<template>
  <span>{{ $format.decimal(quantity, decimalPlaces) }}</span>
</template>

<script setup>

const prop = defineProps({
  product: {
    type: Object,
  },
  quantity : {
    required: true
  }
})

const decimalPlaces = computed(() => {
  return prop.product?.allow_fractional_sales ? 3 : 0
})
</script>

<style lang="scss" scoped>

</style>